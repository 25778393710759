import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import Table from "../../../components/table";
import Layout from "../../../components/layout";

function App({ location }) {

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "place",
      },
      {
        Header: "Navn",
        accessor: "logo.url",
        Cell: (logo) => (
          <div className="flex flex-row">
            <img className="h-6 w-6 fill-current mr-2" src={logo.cell.value}
            >
            </img> {logo.cell.row.values.name}
            </div>
        ),
      },

      {
        Header: "Navn",
        accessor: "name"
      },
      {
        Header: "Poeng",
        accessor: "points"
      },
      {
        Header: "Vunnet",
        accessor: "won"
      },
      {
        Header: "Tap",
        accessor: "lost"
      }
    ],
    []
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await axios("https://api.nifs.no/tournaments/44/stages/682936/table/");
      setData(result.data.teams);
    })();
  }, []);

  return (
    <Layout location={location} className="App">
      <Table type="table" hidden="name" title="Tabelloversikt" columns={columns} data={data} />
    </Layout>
  );
}

export default App;
